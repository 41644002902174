export const setupImageToggler = async () => {
  if (
    document.querySelector("body")?.dataset?.controllerAction ===
    "journeys#show"
  ) {
    console.log("Running setupImageToggler")
    if (document) {
      document
        .querySelector("#toggle-image-size")
        ?.addEventListener("click", () => {
          document.querySelectorAll(".journey-pictures").forEach((e) => {
            e.classList.toggle("!grid-cols-5")
          })
        })
    }
  }
}
